module directives {
    export module compliance {
        interface IExportComplianceSLAMetricsScope extends ng.IScope {
            complianveSLAMetricsData: interfaces.compliance.IComplianceDashboardMissingDocumentsSummary[];
            isLoading: boolean;
            LoadComplianceSLAMetricsData();
            downloadExcel();
        }

        export class exportComplianceSLAMetricsDirective implements ng.IDirective {
            templateUrl = 'templates/modules/applicationmain/compliance/exportComplianceSLAMetricsView.html';
            scope = {
            }

            public complianveSLAMetricsData: interfaces.compliance.IComplianceDashboardMissingDocumentsSummary[];

            constructor(
                private generalService: interfaces.applicationcore.IGeneralService,
                private $state: ng.ui.IStateService,
                private bsLoadingOverlayService,
                private consignmentTemplateService: interfaces.compliance.IConsignmentTemplateService,
            ) {
                   
            }

            link = ($scope: IExportComplianceSLAMetricsScope, $element: ng.IAugmentedJQuery, $state) => {
                var filterList: interfaces.applicationcore.IKeyValue[] = [];
                var searchList: interfaces.applicationcore.IKeyValue[] = [];
                
                $scope.LoadComplianceSLAMetricsData = () => {
                    $scope.isLoading = true;
                    return this.consignmentTemplateService.getComplianceDashboardMissingDocsSummary(false).query({
                    }, (resultList) => {
                            $scope.complianveSLAMetricsData = resultList;
                        $scope.isLoading = false;
                    }).$promise;
                }

                $scope.LoadComplianceSLAMetricsData();       

                $scope.downloadExcel = () =>{
                    $scope.isLoading = true;
                    this.consignmentTemplateService.getComplianceDashboardMissingDocsDetailExcel(false).then(() => {
                        $scope.isLoading = false;
                    }, (data) => {
                        $scope.isLoading = false;
                    });
                }
                
            }

            static factory(): ng.IDirectiveFactory {
                const directive = (generalService, $state,
                    bsLoadingOverlayService, consignmentTemplateService) => new exportComplianceSLAMetricsDirective(generalService, $state, bsLoadingOverlayService,consignmentTemplateService);
                directive.$inject = ["generalService", "$state", "bsLoadingOverlayService","consignmentTemplateService"];

                return directive;
            }
        }

        angular.module("app").directive("gtsComplianceExportsla", exportComplianceSLAMetricsDirective.factory());
    }
}